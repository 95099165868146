:root {
  --bg-color: #eeeded;
  --accent: #003DA2;
  --accent2: #0085FF;
  --accent3: #f0de36;
  --white: #fff;
  --darkaccent2: #a50f0e;
  --black: #000;
  --bs-gutter-x: 3rem;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color:#111013;
}

body {
  font-family: 'DM Sans', sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  //background-color: var(--bg-color);
  margin: 0;
  padding: 0;
  background-color:#F4F5F9;
}

.h1,
.h2 {
  font-size: 3rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}

img {
  max-width: 100%;
}

p {
  font-size: 1rem;
  line-height: 150%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width:1400px) {
    max-width: 1320px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.mainWrapper {
  background: linear-gradient(102deg, #dbf1c3 -7.72%, #dce7e3 86.48%);
  header {
    padding: 0.5rem 0;
    background-color: #fff;
    @media (min-width: 992px) {
      padding: 1rem 0;
    }
    position: relative;
    z-index: 2;

    .logo-wrapper {
      a {
        img {
          max-width: 2rem;
          @media (min-width: 992px) {
            max-width: 3rem;
          }
        }
      }
    }
  }

  .hero-section {
    padding: 30px 0 10rem 0;
    position: relative;
    background-color: #fff;
    @media (min-width: 992px) {
      padding-top: 0px;
    }
    &::after {
      position: absolute;
      content: "";
      background: url(assets/banner_shape_3.webp);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media (min-width:992px) {
        width: 719px;
        height: 591px;
        top: 130px;
        left: 559px;
      }
    }
    .container {
      padding: 0;
      position: relative;
      z-index: 2;
      @media (min-width: 992px) {
        padding: 0 0.5rem;
      }

      .row {
        height: 50vh;
        align-items: center;
        justify-content: center;
        //padding: 0 1rem;
        @media (min-width: 992px) {
          align-items: center;

        }

        .hero-container {
          padding: 0 1rem;
          border-radius: 8px;
          width: 100%;
          flex: 0 0 auto;
          text-align: left;
          @media (min-width: 992px) {
            text-align: center;
            width: 70%;
            padding: 1.5rem;
          }

          .headline-wrapper {
            h1 {
              font-size: 28px;
              font-weight: 700;
              line-height: 95%;
              margin-bottom: 10px;
              color: #111013;
              @media (min-width:992px) {
                font-size: calc(1.375rem + 1.5vw)
              }
              @media (min-width: 1200px) {
                font-size: 2.25rem;
              }
            }
          }
        }

        .sub-hero-section {
          .hero-section-wrapper {
            .hero-section-wrapper-content {
              h2 {
                font-size: 26px;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 10px;
                //color: #ff8565;
                @media  (min-width:992px) {
                  font-size:42px;
                  margin-bottom: 20px;
                }
              }

              p {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 30px;
                @media (min-width: 992px) {
                  font-size: 20px;
                }
              }
            }

            .hero-sectiom-image {
              margin-bottom: 1rem;
            }

            .cta {
              a {
                font-size: 20px;
                font-weight: 600;
                padding: 13px 20px;
                background: #111013;
                color: #fff;
                border-radius: 6px;
                transition: all linear 0.3s;
                margin-bottom: 1rem;
                display: inline-block;
                &:hover,
                &:focus {
                  background-color: var(--darkaccent2);
                }
              }

              span {
                margin-top: .5rem;
                display: block;
                text-align: left;
                color: #ff8565;
                font-weight: 700;

                @media (min-width: 992px) {
                  //margin-top: 1rem;
                  text-align: center;
                }

                font-size: 1rem;
              }
            }
          }
        }

        .congrats {
          max-width: 580px;
          width: 100%;
          background-color: #F8F6F9;
          padding: 1.25rem;
          @media (min-width: 992px) {
            padding: 2rem;
          }

          border-radius: 18px;
          text-align: center;
          h1 {
            font-size: calc(1.375rem + 1.5vw);
            line-height: 1.5;
            @media (min-width: 992px) {
              font-size: 2.5rem;
            }
          }
          h2 {
            color: var(--accent);
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
            line-height: 120%;

            @media (min-width: 992px) {
              font-size: 2rem;
            }
          }

          a {
            display: block;
            margin: 1rem 0;
            background-color: var(--accent2);
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            padding: 1rem;
            font-size: 1.5rem;
            border-radius: 8px;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            transition: all .2s ease-in-out;

            &:hover,
            &:focus {
              background-color: var(--darkaccent2);
            }
          }

          p {
            span {
              color: #0d1282;
              font-weight: bold;
            }
          }

          img {
            margin-top: 1rem;
          }
        }

        .question-wrapper {
          flex: auto;
          width: 100%;
          background-color: #F8F6F9;
          margin: 0 1rem;
          padding: 1.5rem;
          border-radius: 10px;
          @media (min-width:992px) {
            margin: 0;
            padding: 2.5rem;
            max-width: 600px;
          }
          .headline-wrapper {
            h1 {
              color: #ff8565;
              font-size: 15px;
              text-transform: uppercase;
              font-weight: 600;
              text-align: center;
              line-height: 1.2;
              @media (min-width:1200px) {
                font-size: 20px;
              }
            }
          }
          .question-item {
            .question {
              position: relative;
              text-align: center;
              @media (min-width: 992px) {
                padding: 0;
                background-color: transparent;
                box-shadow: none;
                margin: 0;
              }
              border-radius: 18px;
              margin: 1rem auto;
              max-width: 580px;
              width: 100%;
              h2 {
                color: #222222;
                font-size: 25px;
                font-weight: 700;
                @media (min-width:1200px) {
                  font-size: 2rem;
                }
              }
              a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem 0;
                background-color: var(--accent2);
                color: #fff;
                font-weight: bold;
                text-decoration: none;
                padding: 0 1rem ;
                font-size: 1.5rem;
                border-radius: 8px;
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
                height: 50px;
              }
            }

          }
          h1 {
            color: #fff;
          }
        }
      }
    }
  }
  .logo-strip-section {
    background-color: white;
    padding-top: 2rem;
    @media (min-width: 992px) {
      padding-top: 4rem;
    }
    

    .logo-strip {
      flex: 0 0 auto;
      width: 100%;
      margin: 1rem 0;

      .logoitem {
        max-width: 38.75rem;
        margin: 1rem auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 0.5rem;

        @media (min-width: 992px) {
          max-width: 50rem;
        }

        img {
          flex: 0 0 auto;
          width: 25%;
          margin-bottom: 0.25rem;

          @media (min-width: 992px) {
            width: 15%;
          }
        }
      }
    }
  }
  .card-section {
    position: relative;
    z-index: 5;
    margin-top: -20%;
    @media (min-width:992px) {
      margin-top: -5%;
    }
    .container {
      .row {
        justify-content: center;
        .card-image-holder {
          max-width: 580px;
          margin: auto;
          img {
            max-width: 100%;
          }
        }
        .icon-pack {
          margin-top: 1rem;
          .headline-back {
            text-align: left;
            flex: 0 0 auto;
            width: 100%;
            h2 {
              font-size: 1rem;
              font-weight: 400;
              letter-spacing: 2px;
              text-transform: uppercase;
            }
          }
          .logo-icon-pack {
            max-width: 38.75rem;
            margin: 1rem auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 0.5rem;
    
            @media (min-width: 992px) {
              max-width: 50rem;
            }
    
            img {
              flex: 0 0 auto;
              width: 25%;
              margin-bottom: 0.25rem;
    
              @media (min-width: 992px) {
                width: 15%;
              }
            }
          }
        }
      }
    }
  }
  .section1 {
    padding: 3rem 0;
    .container {
      .row {
        align-items: center;
        .section-1-content {
          flex: 0 0 auto;
          width: 100%;
          margin: 0 auto;
          @media (min-width:992px) {
            width: 50%;
            padding: 0 1rem;
            margin: 0;
          }
          h1 {
            color: #111013;
            margin-bottom: 1rem;
            line-height: 120%;
            font-size: 28px;

            @media (min-width: 1200px) {
              font-size: 2.5rem;
            }
          }

          h2 {
            font-size: calc(1.275rem + .3vw);
            font-weight: 500;

            @media (min-width: 1200px) {
              font-size: 1.5rem;
            }
          }

          .cta-holder {
            max-width: 580px;
            margin: 1rem 0;
            a {
                font-size: 15px;
                font-weight: 600;
                padding: 13px 20px;
                background: #111013;
                color: #fff;
                border-radius: 6px;
                transition: all linear 0.3s;
                margin-bottom: 1rem;
                display: inline-block;
                &:hover,
                &:focus {
                  background-color: var(--darkaccent2);
                }
            }

            span {
              margin-top: .5rem;
              text-align: center;
              font-size: 1rem;
              display: block;
            }
          }
        }
        .item-benefits {
          margin: 2rem 0;
          width: 100%;
          @media (min-width:992px) {
            width: 100%;
            padding: 0 1rem;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              flex: 0 0 auto;
              width: 100%;
              margin-bottom: 1rem;
              @media (min-width:992px) {
                max-width: 24%;
                margin: 0 .25rem;
                min-height: 257px;
              }
              .benefit {
                height: 100%;
                border-radius: 10px;
                //box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
                position: relative;
                padding: 45px 25px;
                border: #E8E8E8 1px solid;
                background-color: var(--white);
                .benefitImage {
                  flex: 0 0 auto;
                  //width: 23%;
                  position: relative;

                  img {
                    max-width: 50px;
                  }
                }

                .benefitHeadline {
                  flex: 0 0 auto;
                  //width: 70%;
                  display: flex;
                  flex-wrap: wrap;

                  h3 {
                    font-size: 20px;
                    font-weight: 700;
                    display: block;
                  }

                  p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-top: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 992px) {
      padding: 4rem 0;
    }
  }

  .testimonial {
    padding: 3rem 0;
    text-align: center;

    @media (min-width: 992px) {
      padding: 4rem 0;
    }

    img {
      max-width: 100%;

      @media (min-width: 992px) {
        max-width: 580px;
      }
    }

    .testimonial-headline {
      flex: 0 0 auto;
      width: 100%;

      h1 {
        margin-bottom: 1rem;
        line-height: 120%;
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }
    }

    .testimonial-content-holder {
      flex: 0 0 auto;
      width: 100%;

      h3 {
        font-weight: 500;
      }
    }

    .testimonial-list {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;

      .testimonial-item {
        margin-bottom: 1rem;
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 1rem;

        @media (min-width: 992px) {
          width: 33%;
          padding: 0 1rem;
        }

        .testimonial-inner {
          padding: 1.56rem;
          text-align: left;
          box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          border: 1px solid #E7EAEC;
          background-color: var(--white);
          .testimonial-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .testimonial-left {
              img {
                max-width: 50%;
                border-radius: 10px;
              }
            }

            .testimonial-right {
              .star-rating {
                display: flex;

                img {
                  width: 150px;
                }
              }
            }
          }

          .testimonial-bottom {
            h3 {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 4rem 0 6rem 0;
    .top-footer {
      p {
        font-size: .875rem;
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }

    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
        width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @media(min-width:992px) {
          justify-content: space-between;
        }

        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;

          @media(min-width:992px) {
            width: auto;
          }
        }

        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;

          @media(min-width:992px) {
            width: auto;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
              &:nth-child(1) {
                &::after {
                  content: '|';
                  margin: 0 1rem;
                }
              }

              a {
                text-decoration: none;
                color: var(--black);
              }
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;

      h1 {
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width:1200px) {
          font-size: 2.5rem;
          ;
        }
      }

      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }

    .policy-paragraph {
      padding-bottom: 4rem;

      p {
        margin-bottom: .5rem;
      }

      h2 {
        font-size: calc(1.325rem + .9vw);
        margin: 1rem 0;

        @media(min-width:1200px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: calc(1.3rem + .6vw);
        margin-bottom: 1rem;

        @media(min-width:1200px) {
          font-size: 1.75rem;
        }
      }

      h3 {
        font-size: calc(1.275rem + .3vw);

        @media(min-width:1200px) {
          font-size: 1.5rem;
        }
      }

      ul {
        list-style-type: disc;
        margin-bottom: .5rem;
      }
    }
  }
}